import {Component, OnInit} from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private apiUrl = '/api/index.php/v1/login';
  data: any = {login: '', password: ''};
  errors: any;
  userExpired: any = this.formatDate(new Date());
  infoExpired: boolean = false;

  constructor(private http: Http, private router: Router) {}

  doSubmitLogin() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    let jsonBody = JSON.stringify(this.data);
    console.log(jsonBody);
    this.http.post(this.apiUrl, jsonBody, {headers: headers}).toPromise().then(res => {
      const data = res.json();
      if(data.fine < this.userExpired) {
        this.router.navigate(['/login']);
        this.infoExpired = true;
      } else {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', this.data.login);
        this.router.navigate(['/dashboard']);
      }

      if(data.fine == '') {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', this.data.login);
        this.router.navigate(['/dashboard']);
      }
    }).catch(err => {
      this.errors = err.json();
    });
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/dashboard']);
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return day + '/' + month + '/' + year;
  }
}
