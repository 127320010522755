import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Http} from '@angular/http';

@Component({
  selector: 'app-schede-detail',
  templateUrl: './schede-detail.component.html',
  styleUrls: ['./schede-detail.component.scss']
})
export class SchedeDetailComponent implements OnDestroy {

  id: number;
  private sub: any;
  schede: any = [];
  title: string;

  constructor(private route: ActivatedRoute, private http: Http, private router: Router, private _location: Location) {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.initializeComponent();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private initializeComponent() {
    this.http.get('/api/index.php/v1/categories/' + this.id).toPromise().then(res => {
      let response = res.json();
      this.title = response.name;
      this.schede = response.scheda;
      console.log(this.schede);
    }).catch(err => {
      console.log(err);
    });
  }

  deleteScheda(schedaId) {
    this.http.delete('/api/index.php/v1/scheda/' + schedaId).toPromise().then(res => {
      this.http.get('/api/index.php/v1/categories/' + this.id).toPromise().then(res => {
        let response = res.json();
        this.title = response.name;
        this.schede = response.scheda;
        console.log(this.schede);
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);
    });
  }

  back() {
    this._location.back();
  }

}
