import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Http, Headers} from '@angular/http';
import {NgForm} from '@angular/forms';
import {Observable, Subscription} from 'rxjs/Rx';

@Component({
  selector: 'app-users-post',
  templateUrl: './users-post.component.html',
  styleUrls: ['./users-post.component.scss']
})
export class UsersPostComponent {
  user: any = [];
  @ViewChild('userPost') public userPost: NgForm;
  private subscription: Subscription;
  private timer: Observable<any>;
  showSuccess: boolean = false;

  constructor(private route: ActivatedRoute, private http: Http, private router: Router, private _location: Location) {
  }

  doSubmitUserPost(form:NgForm) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post('/api/index.php/v1/login/registration', form.value, {headers: headers}).toPromise().then(res => {
      form.reset();
      if(res.status == 200) {
        this.timeout();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  timeout() {
    this.showSuccess = true;

    this.timer = Observable.timer(3000); // 5000 millisecond means 5 seconds
    this.subscription = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showSuccess = false;
    });
  }

  back() {
    this._location.back();
  }
}
