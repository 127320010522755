import {Component, OnChanges, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Http} from '@angular/http';

@Component({
  selector: 'app-schede-list',
  templateUrl: './schede-list.component.html',
  styleUrls: ['./schede-list.component.scss']
})
export class SchedeListComponent implements OnInit {

  categories: any = [];

  constructor(private route: ActivatedRoute, private http: Http, private router: Router, private _location: Location) { }

  ngOnInit() {
    this.http.get('/api/index.php/v1/categories').toPromise().then(res => {
      this.categories = res.json();
      console.log(this.categories);
    }).catch(err => {
      console.log(err);
    });
  }

  trackByFn(index, category) {
    return category.id;
  }

  back() {
    this._location.back();
  }
}
