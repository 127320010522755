import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Http, Headers} from '@angular/http';
import {NgForm} from '@angular/forms';
import {Observable, Subscription} from 'rxjs/Rx';

@Component({
  selector: 'app-users-put',
  templateUrl: './users-put.component.html',
  styleUrls: ['./users-put.component.scss']
})
export class UsersPutComponent implements OnDestroy {
  id: number;
  private sub: any;
  user: any = [];
  @ViewChild('userPost') public userPost: NgForm;
  private subscription: Subscription;
  private timer: Observable<any>;
  showSuccess: boolean = false;

  constructor(private route: ActivatedRoute, private http: Http, private router: Router, private _location: Location) {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.initializeComponent();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private initializeComponent() {
    this.http.get('/api/index.php/v1/users/' + this.id).toPromise().then(res => {
      this.user = res.json();
    }).catch(err => {
      console.log(err);
    });
  }

  doSubmitUserPost(form:NgForm) {
    let headers = new Headers();
    let jsonBody;
    if (form.value.password == undefined) {
      jsonBody = {name: form.value.name, surname: form.value.surname, email: form.value.email, inzio: form.value.inzio, fine: form.value.fine};
    } else {
      jsonBody = form.value;
    }
    headers.append('Content-Type', 'application/json');
    this.http.put('/api/index.php/v1/users/' + this.id, jsonBody, {headers: headers}).toPromise().then(res => {
      if(res.status == 200) {
        this.timeout();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  timeout() {
    // set showloader to true to show loading div on view
    this.showSuccess = true;

    this.timer = Observable.timer(3000); // 5000 millisecond means 5 seconds
    this.subscription = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showSuccess = false;
    });
  }

  back() {
    this._location.back();
  }

}
