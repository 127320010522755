import {NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import {LoginComponent} from './login/login.component';
import {AuthGuardService} from './auth/auth-guard.service';
import {AuthService} from './auth/auth.service';
import {CategoriesComponent} from './categories/categories.component';
import {SchedaComponent} from './scheda/scheda.component';
import {BackendComponent} from './backend/backend.component';
import {UsersComponent} from './users/users.component';
import {UsersPutComponent} from './users-put/users-put.component';
import {UsersPostComponent} from './users-post/users-post.component';
import {CategoriesListComponent} from './categories-list/categories-list.component';
import {CategoriesPostComponent} from './categories-post/categories-post.component';
import {CategoriesPutComponent} from './categories-put/categories-put.component';
import {SchedeListComponent} from './schede-list/schede-list.component';
import {SchedeDetailComponent} from './schede-detail/schede-detail.component';
import {SchedePostComponent} from './schede-post/schede-post.component';
import {SchedePutComponent} from './schede-put/schede-put.component';
import {QuizComponent} from "./quiz/quiz.component";

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'categoria/:id', component: CategoriesComponent, canActivate: [AuthGuardService] },
  { path: 'scheda/:id', component: SchedaComponent, canActivate: [AuthGuardService] },
  { path: 'quiz', component: QuizComponent, canActivate: [AuthGuardService] },
  { path: 'backend', component: BackendComponent, canActivate: [AuthGuardService] },
  { path: 'backend/utenti', component: UsersComponent, canActivate: [AuthGuardService] },
  { path: 'backend/utenti/new', component: UsersPostComponent, canActivate: [AuthGuardService] },
  { path: 'backend/utenti/:id', component: UsersPutComponent, canActivate: [AuthGuardService] },
  { path: 'backend/categorie', component: CategoriesListComponent, canActivate: [AuthGuardService] },
  { path: 'backend/categorie/new', component: CategoriesPostComponent, canActivate: [AuthGuardService] },
  { path: 'backend/categorie/:id', component: CategoriesPutComponent, canActivate: [AuthGuardService] },
  { path: 'backend/schede', component: SchedeListComponent, canActivate: [AuthGuardService] },
  { path: 'backend/scheda/new', component: SchedePostComponent, canActivate: [AuthGuardService] },
  { path: 'backend/schede/:id', component: SchedeDetailComponent, canActivate: [AuthGuardService] },
  { path: 'backend/scheda/:id', component: SchedePutComponent, canActivate: [AuthGuardService] },
  { path: '**', component: DashboardComponent, canActivate: [AuthGuardService] }, // 404
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: [
    AuthService,
    AuthGuardService
  ]
})
export class AppRoutingModule {}
