import {Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { Http, Headers } from '@angular/http';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  user: any;
  getCategoryUrl: any = '/api/index.php/v1/categories/84';
  menu: any = [];
  showStyle: false;

  constructor(private http: Http, public auth: AuthService) {}

  ngOnInit() {
    this.isLoggedIn$ = this.auth.isLoggedIn;
    this.auth.userLoggedIn.subscribe(data => {
      if (data == true) {
        this.user = localStorage.getItem('user');
      }
    });

    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    this.http.get(this.getCategoryUrl, {headers: headers}).toPromise().then(res => {
      let response = res.json();
      this.menu = response.file;
      console.log(this.menu);
    }).catch(err => {
      console.log(err);
    });
  }

  getStyle() {
    if (this.showStyle) {
      return 'block';
    } else {
      return 'none';
    }
  }

  onLogout() {
    this.auth.logout();
  }
}
