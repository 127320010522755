import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Http, Headers} from '@angular/http';
import {NgForm} from '@angular/forms';
import {Observable, Subscription} from 'rxjs/Rx';

@Component({
  selector: 'app-schede-post',
  templateUrl: './schede-post.component.html',
  styleUrls: ['./schede-post.component.scss']
})
export class SchedePostComponent implements OnInit {
  schede: any = {'sezione': [ {'Contenuto': '', 'quiz': []} ]};
  categoriesOption: any = [];
  @ViewChild('schedaPost') public schedaPost: NgForm;
  private subscription: Subscription;
  private timer: Observable<any>;
  showSuccess: boolean = false;
  image: any;

  public options: Object = {
    charCounterCount: true,
    imageUploadParam: 'image_param',
    imageUploadURL: 'assets/upload_image',
    imageUploadParams: {id: 'my_editor'},
    imageUploadMethod: 'POST',
    imageDefaultWidth: 0,
    events:  {
      'froalaEditor.initialized':  function () {
        console.log('initialized');
      },
      'froalaEditor.image.beforeUpload':  function  (e,  editor,  images) {
        //Your code
        if  (images.length) {
          // Create a File Reader.
          const  reader  =  new  FileReader();
          // Set the reader to insert images when they are loaded.
          reader.onload  =  (ev)  =>  {
            const  result  =  ev.target['result'];
            editor.image.insert(result,  null,  null,  editor.image.get());
            console.log(ev,  editor.image,  ev.target['result']);
          };
          // Read image as base64.
          reader.readAsDataURL(images[0]);
        }
        // Stop default upload chain.
        return  false;
      }

    }
  }

  constructor(private route: ActivatedRoute, private http: Http, private router: Router, private _location: Location) {}

  ngOnInit() {
    console.log(this.schede.sezione);
    this.http.get('/api/index.php/v1/categories').toPromise().then(res => {
      let response = res.json();
      response.forEach((item, index) => {
        if (item.is_last == 1) {
          this.categoriesOption.push(item);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  }

  doSubmitSchedaPost(form:NgForm) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let jsonBody = {
      'id_Categoria': form.value.id_Categoria,
      'Titolo': form.value.Titolo,
      'sezione': this.schede.sezione,
      'Immagine': this.schede.Immagine
    };

    this.http.post('/api/index.php/v1/scheda', jsonBody, {headers: headers}).toPromise().then(res => {
      if(res.status == 200) {
        this.timeout();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  timeout() {
    // set showloader to true to show loading div on view
    this.showSuccess = true;

    this.timer = Observable.timer(3000); // 5000 millisecond means 5 seconds
    this.subscription = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showSuccess = false;
    });
  }

  addSezione() {
    var item = {
      'Contenuto': '',
      'quiz': []
    };
    this.schede.sezione.push(item);
  }

  addQuiz(quiz) {
    var item = {
      'Domanda': '',
      'resposta': ''
    };
    quiz.push(item);
  }

  removeSezione(index, array) {
    console.log(array);
    array.splice(index, 1);
    console.log(array);
  }

  removeQuiz(index, array) {
    array.splice(index, 1);
    console.log(array);
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.schede.Immagine = myReader.result;
    }
    myReader.readAsDataURL(file);
  }

  back() {
    this._location.back();
  }

}
