import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Http} from '@angular/http';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: any = [];
  userExpired: any = this.formatDate(new Date());

  constructor(private route: ActivatedRoute, private http: Http, private router: Router, private _location: Location) { }

  ngOnInit() {
    this.http.get('/api/index.php/v1/users/').toPromise().then(res => {
      this.users = res.json();
    }).catch(err => {
      console.log(err);
    });
  }

  deleteUser(userId) {
    this.http.delete('/api/index.php/v1/users/' + userId).toPromise().then(res => {
      this.http.get('/api/index.php/v1/users/').toPromise().then(res => {
        this.users = res.json();
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return day + '/' + month + '/' + year;
  }

  back() {
    this._location.back();
  }
}
