import {Component, OnInit} from '@angular/core';
import { Http, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  getCategoryUrl: any = '/api/index.php/v1/categories/84';
  getCategory: any = [];

  constructor(private http: Http, private router: Router) {
  }

  async ngOnInit() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    this.http.get(this.getCategoryUrl, {headers: headers}).toPromise().then(res => {
      let response = res.json();
      this.getCategory = response.file;
      console.log(this.getCategory);
    }).catch(err => {
      console.log(err);
    });
  }

  categoryParent(categoryId) {
    this.router.navigate(['/categories', { id: categoryId }]);
  }
}
