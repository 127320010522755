import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Http, Headers} from '@angular/http';
import {NgForm} from '@angular/forms';
import {Observable, Subscription} from 'rxjs/Rx';

@Component({
  selector: 'app-categories-put',
  templateUrl: './categories-put.component.html',
  styleUrls: ['./categories-put.component.scss']
})
export class CategoriesPutComponent implements OnDestroy {
  id: number;
  private sub: any;
  categories: any = [];
  categoriesOption: any = [];
  @ViewChild('categoryPost') public userPost: NgForm;
  private subscription: Subscription;
  private timer: Observable<any>;
  showSuccess: boolean = false;
  image: any;

  constructor(private route: ActivatedRoute, private http: Http, private router: Router) {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.initializeComponent();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private initializeComponent() {
    this.http.get('/api/index.php/v1/categories').toPromise().then(res => {
      let response = res.json();
      response.forEach((item, index) => {
        if (item.is_last != 1) {
          this.categoriesOption.push(item);
        }
      });
    }).catch(err => {
      console.log(err);
    });
    this.http.get('/api/index.php/v1/categories/' + this.id).toPromise().then(res => {
      this.categories = res.json();
    }).catch(err => {
      console.log(err);
    });
  }

  doSubmitCategoryPost(form:NgForm) {
    console.log(form.value);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.put('/api/index.php/v1/categories/' + this.id, form.value, {headers: headers}).toPromise().then(res => {
      if(res.status == 200) {
        this.timeout();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  timeout() {
    // set showloader to true to show loading div on view
    this.showSuccess = true;

    this.timer = Observable.timer(3000); // 5000 millisecond means 5 seconds
    this.subscription = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showSuccess = false;
    });
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.categories.img = myReader.result;
    }
    myReader.readAsDataURL(file);
  }
}
