import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoriesComponent } from './categories/categories.component';
import { DetailComponent } from './detail/detail.component';
import { SchedaComponent } from './scheda/scheda.component';
import { DecodeHtmlStringPipe } from './decode-html-string.pipe';
import { SafePipe } from './safe.pipe';
import { BackendComponent } from './backend/backend.component';
import { UsersComponent } from './users/users.component';
import { UsersPostComponent } from './users-post/users-post.component';
import { UsersPutComponent } from './users-put/users-put.component';
import { CategoriesListComponent } from './categories-list/categories-list.component';
import { CategoriesPostComponent } from './categories-post/categories-post.component';
import { CategoriesPutComponent } from './categories-put/categories-put.component';
import { SchedeListComponent } from './schede-list/schede-list.component';
import { SchedeDetailComponent } from './schede-detail/schede-detail.component';
import { SchedePostComponent } from './schede-post/schede-post.component';
import { SchedePutComponent } from './schede-put/schede-put.component';
import { QuizComponent } from './quiz/quiz.component';

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CategoriesComponent,
    DetailComponent,
    SchedaComponent,
    DecodeHtmlStringPipe,
    SafePipe,
    BackendComponent,
    UsersComponent,
    UsersPostComponent,
    UsersPutComponent,
    CategoriesListComponent,
    CategoriesPostComponent,
    CategoriesPutComponent,
    SchedeListComponent,
    SchedeDetailComponent,
    SchedePostComponent,
    SchedePutComponent,
    QuizComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpModule,
    SwiperModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
