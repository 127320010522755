import {Component, OnDestroy, OnInit} from '@angular/core';
import { Http } from '@angular/http';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {
  id: number;
  private sub: any;
  scheda: any = [];
  getSubCategories: any [];
  response: any = {};

  constructor(private route: ActivatedRoute, private http: Http, private router: Router, private _location: Location) {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.initializeComponent();
    });
  }

  ngOnInit() {
    this.http.get('/api/index.php/v1/categories/' + this.id).toPromise().then(res => {
      this.response = res.json();
      console.log(this.response);
      if (this.response.is_last != 0) {
        this.scheda = this.response.scheda;
        console.log(this.scheda);
      } else {
        this.getSubCategories = this.response.file;
        console.log(this.getSubCategories);
      }
    }).catch(err => {
      console.log(err);
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private initializeComponent() {
    this.http.get('/api/index.php/v1/categories/' + this.id).toPromise().then(res => {
      this.response = res.json();
      console.log(this.response);
      if (this.response.is_last != 0) {
        this.scheda = this.response.scheda;
        console.log(this.scheda);
      } else {
        this.getSubCategories = this.response.file;
        console.log(this.getSubCategories);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  back() {
    this._location.back();
  }
}
