import {Component, DoCheck, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Http} from '@angular/http';
import {Location} from '@angular/common';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-scheda',
  templateUrl: './scheda.component.html',
  styleUrls: ['./scheda.component.scss']
})
export class SchedaComponent implements OnDestroy {
  id: number;
  private sub: any;
  infoScheda: any = [];
  sezione: any = [];
  quiz: any = [];
  getSectionQuiz: any = [];
  goToNewLeasson: any;
  hideButton: boolean = false;

  // Swiper Slider
  config: any = {
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    pagination: {
      el: '.pagination',
      clickable: true,
      renderBullet: function (index, className) {
        index++;
        return '<span class="tab__link ' + className + '">' + index + '</span>';
      },
    },
    noSwipingSelector: 'object'
  };

  constructor(private route: ActivatedRoute, private http: Http, private router: Router, private _location: Location) {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.initializeComponent();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private initializeComponent() {
    this.http.get('/api/index.php/v1/scheda/' + this.id).toPromise().then(res => {
      let response = res.json();
      this.infoScheda = response;
      this.getSectionQuiz = response.sezione;
      console.log(this.getSectionQuiz);
      for (let i = response.sezione.length; i--;) {
        let index = i  + 1;
        if (response.sezione[i].quiz.length > 0) {
          let item = {
            'Contenuto': 'Vuoi effettuare un test delle lezioni svolte?',
            'quiz': response.sezione[i].quiz,
            'presenzaQuiz': '1'
          };
          response.sezione.splice(index, 0, item);
        }
      }

      this.http.get('/api/index.php/v1/categories/' + this.infoScheda.id_Categoria).toPromise().then(res => {
        let responseCategories = res.json();
        responseCategories.scheda.forEach((key: any, val: any) => {
          if ( key.id == this.infoScheda.id) {
            val++;
            console.log(responseCategories.scheda[val].id);
            if (typeof responseCategories.scheda[val].id === 'undefined') {
              this.hideButton = true;
              console.log('true');
            } else {
              this.goToNewLeasson = responseCategories.scheda[val].id;
              console.log('goPage');
            }
          }
        });
      }).catch(err => {
        console.log(err);
        this.hideButton = true;
      });

    }).catch(err => {
      console.log(err);
    });
  }

  goToQuiz(quiz) {
    this.router.navigate(['./quiz', {quiz: JSON.stringify(quiz), image: this.infoScheda.Immagine}]);
  }

  back() {
    this._location.back();
  }
}
