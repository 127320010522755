import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  quiz: any = [];
  image: any;

  // Swiper Slider
  config: any = {
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    pagination: {
      el: '.pagination',
      clickable: true,
      renderBullet: function (index, className) {
        index++;
        return '<span class="tab__link ' + className + '">' + index + '</span>';
      },
    },
  };

  constructor(private route: ActivatedRoute, private router: Router, private _location: Location) {
    this.route.paramMap.subscribe(params => {
      this.quiz = params.getAll('quiz');
      this.quiz = JSON.parse(this.quiz);
      this.image = params.getAll('image');
      console.log(this.quiz);
    });
  }

  ngOnInit() {

  }

  checkUserChoice(userChoice, index) {
    $('.btn-answer._' + index).each(function () {
      $(this).removeClass('active');
      if (userChoice == 'Corretta' && $(this).hasClass('true')) {
        $(this).addClass('active');
        $(this).addClass('correct');
        $('.btn-answer.false._' + index).addClass('uncorrect');
      } else if (userChoice == 'Errata' && $(this).hasClass('false')) {
        $(this).addClass('active');
        $(this).addClass('uncorrect');
        $('.btn-answer.true._' + index).addClass('correct');
      }
    });
  }

  back() {
    this._location.back();
  }
}
