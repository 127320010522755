import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private loggedInBackend = new BehaviorSubject<boolean>(false);
  private userIn = new BehaviorSubject<boolean>(false);
  constructor(private router: Router) {}

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isLoggedInBackend() {
    return this.loggedInBackend.asObservable();
  }

  get userLoggedIn() {
    return this.userIn.asObservable();
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    // Check whether the token is expired and return
    // true or false
    if (token == null && user == null) {
      this.loggedIn.next(false);
      this.userIn.next(false);
      return false;
    } else {
      this.loggedIn.next(true);
      this.userIn.next(true);
      return true;
    }
  }

  public isAuthenticatedBackend(): boolean {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    // Check whether the token is expired and return
    // true or false
    if (token == null && user != 'admin@aulaestrema.cloud') {
      this.loggedIn.next(false);
      this.loggedInBackend.next(false);
      this.userIn.next(false);
      return false;
    } else {
      this.loggedIn.next(true);
      this.loggedInBackend.next(true);
      this.userIn.next(true);
      return true;
    }
  }

  logout() {
    this.loggedIn.next(false);
    this.userIn.next(false);
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
