import {Component, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Http} from '@angular/http';
import set = Reflect.set;

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  categories: any = [];
  showParent: boolean = false;
  idCategory: any = '';
  showButtonBack: boolean = false;

  constructor(private route: ActivatedRoute, private http: Http, private router: Router) { }

  ngOnInit() {
    this.http.get('/api/index.php/v1/categories').toPromise().then(res => {
      this.categories = res.json();
      console.log(this.categories);
    }).catch(err => {
      console.log(err);
    });
  }

  deleteCategory(categoryId) {
    this.http.delete('/api/index.php/v1/categories/' + categoryId).toPromise().then(res => {
      this.http.get('/api/index.php/v1/categories/').toPromise().then(res => {
        let response = res.json();
        this.categories = [];
        if (response.parent == null) {
          this.showParent = false;
          this.showButtonBack = false;
          this.categories = response;
        } else {
          this.showParent = true;
          this.categories = response.file;
        }
      }).catch(err => {
        console.log(err);
      });
    }).catch(err => {
      console.log(err);
    });
  }

  loadParentCategories(categoryId) {
    this.http.get('/api/index.php/v1/categories/' + categoryId).toPromise().then(res => {
      let response = res.json();
      this.categories = response.file;
      this.showParent = true;
      this.showButtonBack = true;
      this.idCategory = categoryId;
    }).catch(err => {
      console.log(err);
    });
  }

  trackByFn(index, category) {
    return category.id;
  }

  async backButton() {
    await this.http.get('/api/index.php/v1/categories/' + this.idCategory).toPromise().then(res => {
      let response = res.json();
      this.categories = [];
      if (response.parent == null) {
        this.showParent = false;
        this.showButtonBack = false;
        this.categories = response;
      } else {
        this.showParent = true;
        this.categories = response.file;
      }
      console.log(this.categories);
    }).catch(err => {
      console.log(err);
    });
  }

}
